import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Vira from '../assets/img/vira.png';


const Wa = () => {
  return ( 
    <FloatingWhatsApp 
    phoneNumber="6282322525783"
    accountName="Klinik Psikolog Vira"
    avatar={Vira}
    statusMessage='Setiap jiwa berhak untuk bertumbuh kuat dan menjadi terang untuk menerangi.'
    allowEsc
    placeholder='Ceritakan'
    chatMessage='Hallo, ada saya disini, apa keluh kesahmu?'
    allowClickAway
    notification
    notificationSound
        />);
}

export default Wa